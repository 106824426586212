import * as React from "react";

export const Onama: React.FC = () => {
  return (
    <>
      <section className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h3> O nama</h3>

              <p className="page-breadcrumb">
                <a href="/">Početna</a> / O nama
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section-content-block who-are-we">
        <div className="container">
          <div className="row">
            <div className="about-us">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="about-us-text">
                  <h2 className="section-heading-inline">O nama</h2>
                  <p>
                    "Đedovina" je emisija koja kroz audio-vizuelni segment,
                    predstavlja krajeve na teritoriji Crne Gore.
                  </p>
                  <p>
                    "Đedovina" epizode emituje na istoimeni Youtube kanal, kao i
                    na društvenim mrežama (Facebook, Tiktok, Instagram i
                    Twitter)
                  </p>
                  <p>
                    Cilj emisije je prikazati manje poznate lokalitete u Crnoj
                    Gori (planinske, mediteranske i kontinentalne).
                  </p>
                  <p>
                    Misija projekta je da prikaže potencijal navedenih
                    lokaliteta, njihovo prirodno, turističko i poljoprivredno
                    bogatstvo.
                  </p>
                  <p>
                    Vizija projekta je da se preko društvenih mreža lakše dopre
                    ciljnoj grupi i prikaže im se šansa i način kojim bi na
                    svojoj đedovini napravili sopstveni biznis.
                  </p>
                  <p>
                    Ovaj projekat ima potencijal da inspiriše mlade ljude da
                    istraže i iskoriste potencijale u svojoj đedovini.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-sm-12 hidden-xs">
                <figure className="about-us-img">
                  <img src="images/djedovina-logo.jpg" alt="About_image" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-content-block event-section-1 section-secondary-bg">
        <div className="container wow fadeInUp">
          <div className="row section-heading-wrapper">
            <div className="col-md-12 col-sm-12 text-center">
              <h2 className="section-heading">
                <span>Sponzor</span> emisije
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12"></div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="event_wrapper-1 xs-margin">
                <figure className="event-img">
                  <img src="images/investicioni-fond-logo.jpg" alt="" />
                </figure>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12"></div>
          </div>
        </div>
      </section>

      <section className="section-content-block event-section-1 section-secondary-bg">
        <div className="container wow fadeInUp">
          <div className="row section-heading-wrapper">
            <div className="col-md-12 col-sm-12 text-center">
              <h2 className="section-heading">
                <span>Prijatelji</span> emisije
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="event_wrapper-1 xs-margin">
                <figure className="event-img">
                  <img src="images/montevideo-logo.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="event_wrapper-1 xs-margin">
                <figure className="event-img">
                  <img src="images/prijestonica-cetinje-logo.png" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="event_wrapper-1 xs-margin">
                <figure className="event-img">
                  <img src="images/cbcg-logo.jpg" alt="" />
                </figure>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="event_wrapper-1 xs-margin">
                <figure className="event-img">
                  <img src="images/tobar-logo.jpg" alt="" />
                </figure>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="event_wrapper-1 xs-margin" style={{ border: "none", background: "white" }} >
                <figure className="event-img">
                  <img src="images/TO MK lat.webp" alt="event" />
                </figure>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="event_wrapper-1 xs-margin">
                <figure className="event-img">
                  <img src="images/rozaje-logo.jpg" alt="event" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
